
import { IWorkflowSummary } from '@/entity/rh/workflow';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { workflowApi } from '@/wapi/workflow-api';
@Component
export default class UserLeave extends Vue {
    @Prop({ required: true }) workflowLeaveList!: IWorkflowSummary[];
    @Prop({ required: true }) userId!: string;
    @Prop({ required: true }) isCurrentUserInformations!: boolean;
    worflow: NU<IWorkflowSummary[]> = [];;

    isVisible: boolean = false;

    get isReadOnly(): boolean {
        return !vxm.user.isAdmin;
    }

    private createLeave() {
        this.$router.push({ name: 'leave-information', params: { id: this.userId, leaveId: '0' } });
    }

    private consultLeave(workflowId: number) {
        this.$router.push({ name: 'leave-information', params: { id: this.userId, leaveId: workflowId + '' } });
    }

    async deleteLeave(item: IWorkflowSummary): Promise<void> {
        const result = await workflowApi.delete(this.userId, item.id);
        
        if (result.cancelled === false && !result.error && result.datas) {
            this.$bvToast.toast('Suppression effectué avec succès', {
                title: `Demande de congé supprimée`,
                variant: 'success',
                solid: true
            });
            this.$emit('update-leaves');
        }

    }

    private transformToStatus(val: number): string {
        switch (val) {
            case -2:
                return 'Annulé';
            case -1:
                return 'Refusé';
            case 0:
                return 'En cours';
            case 1:
                return 'Soumis';
            case 2:
                return 'Validé';
            default:
                return 'inconnu';
        }
    }

    private getFormattedDate(date: Date) {
        return new Date(date).toLocaleDateString('fr-FR');
    }
}
